//(Required file)
@import 'variable';

// Import Bootstrap source files (Required file)
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import 'theme-colors/theme-colors';

@import 'core/core';

//This is for the responsive (Required file)
@import 'responsive';

//In This scss you can write your scss
@import 'custom';

// this is for the perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';