// Theme colors
#main-wrapper {

  &[data-layout='vertical'],
  &[data-layout='horizontal'] {

    /*This is for the logo bg*/
    .topbar .top-navbar .navbar-header {
      .beta {
        position: relative;
        top: 1em;
        left: 0em;
        font-size: 16px;
        font-weight: bolder;
        color: #ff9800;
      }

      &[data-logobg='skin5'] {
        background: $skin5;
      }
    }

    .topbar .navbar-collapse,
    .topbar {
      &[data-navbarbg='skin5'] {
        background: $skin5;
      }
    }
  }
}