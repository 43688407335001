/*==============================================================
 For Desktop & above all (1650px)
 ============================================================== */
@media (min-width: 992px) and (max-width: 1199.98px) {

  tbody {
    max-height: calc(100vh - 205px);
  }

  .col-4 {
    max-height: calc(100vh - 69px);
  }
}

/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */
@media (min-width: 1024px) {
  .page-wrapper {
    margin-left: $sidebar-width-full;
  }

  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    /* font-size: 18px; */
    max-height: calc(100vh - 205px);
  }
}

/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */
@media (min-width: 800px) {
  .page-wrapper {
    .topbar .top-navbar .navbar-header {
      width: 250px;
    }

    margin-left: $sidebar-width-full;
  }

  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}

@media (max-width: 769px) {

  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}

@media (min-width: 1373px) {
  .hided {
    display: block;
  }
}

@media (max-width: 1374px) {
  .hide {
    display: none;
  }
}

@media (min-width: 500px) {

  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}

/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */

@include media-breakpoint-up(md) {

  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}


@media (min-width: 1761px) {
  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}

@media (max-width: 1760px) {
  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }

}

@media (max-width: 1570px) {
  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }

}

@media (min-width: 2400px) {
  .col-4 {
    max-height: calc(100vh - 69px);
  }

  tbody {
    max-height: calc(100vh - 205px);
  }
}