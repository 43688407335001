/*******************
customizer
*******************/
.customizer {
  background: $theme-light;
  position: fixed;
  height: 100%;
  width: 900px;
  top: 0px;
  right: -900px;
  z-index: 2003;
  /* box-shadow: 0px 10px 8px 5px #888888;*/
  /* box-shadow: $shadow; */
  transition: 0.3s ease-in;

  .service-panel-toggle {
    background: transparent;
    padding: 12px 20px;
    color: $white;
    position: absolute;
    top: 93%;
    left: -50px;
  }

  &.show-service-panel {
    right: 0px;
  }

  .customizer-body {
    position: relative;
    background: white;
    height: 100%;
  }
}

.body-overlay {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

body .show-service-panel .body-overlay {
  opacity: 1;
  /* z-index: 1; */
  pointer-events: auto;
}

.custom-control-label::after,
.custom-control-label::before {
  top: 0.15rem;
}

.theme-color {
  padding: 0px;
  margin: 0px;
  list-style: none;

  .theme-item {
    list-style: none;
    display: inline-block;
    margin-right: 5px;

    .theme-link {
      border-radius: 100%;
      width: 20px;
      height: 20px;
      display: block;

      &[data-logobg='skin5'],
      &[data-navbarbg='skin5'],
      &[data-sidebarbg='skin5'] {
        background: $skin5;
      }
    }
  }
}