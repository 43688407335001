// ======================================================
// Custom layout variables
// ======================================================
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

// Remove Warning: 1 rules skipped due to selector errors: .custom-file-input:lang(en) ~ .custom-file-label -> unmatched pseudo-class :lang
$custom-file-text: (
);

// ======================================================
// Theme Global setting colors
// ======================================================
$themecolor: #2196f3;
$themecolor-alt: #26c6da;
$theme-light: #fff;

$skin5: #1c2834;

// ======================================================
// Topbar settings
// ======================================================

$topbar: $theme-light;
$topbar-height: 64px;
$topbar-navlink-padding: 0px 14px;
$topbar-navlink-font-size: 0.875rem;
$topbar-navlink-height: 64px;
$topbar-navbrand-padding: 0 10px 0 10px;

// ======================================================
// Sidebar settings
// ======================================================

$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #212529;
$sidebar-icons-dark: #4f5467;

$sidebar-width-full: 350px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 65px;

// ======================================================
// Layout settings
// ======================================================
$boxed-width: 1200px;

// ======================================================
// Common settings
// ======================================================
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.05);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;

// ======================================================
// Mixin settings
// ======================================================
%square {
  border-radius: 0px;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

// ======================================================
// Bootstrap override colors
// ======================================================
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #a1aab2 !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2196f3 !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #f62d51 !default;
$orange: #fb8c00 !default;
$yellow: #ffbc34 !default;
$green: #36bea6 !default;
$teal: #20c997 !default;
$cyan: #4fc3f7 !default;
$primary: #1976d2 !default;
$text-muted: $gray-500 !default;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$muted: $gray-500 !default;
$cyan: $cyan !default;
$orange: $orange !default;
$theme-colors: (
  ) !default;

$theme-colors: map-merge(('primary': $primary,
      'secondary': $secondary,
      'success': $success,
      'info': $info,
      'warning': $warning,
      'danger': $danger,
      'light': $light,
      'dark': $dark,
      'muted': $muted,
      'cyan': $cyan,
      'orange': $orange,
      'purple': $purple ),
    $theme-colors
);

// ======================================================
// Enable optional
// ======================================================
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// ======================================================
// Body
// ======================================================
$main-body-bg: #fff !default;
$body-bg: #eef5f9;
$body-color: #3e5569 !default;
$grid-gutter-width: 20px !default;

// ======================================================
// Typography
// ======================================================
$font-size-base: 0.875rem;
$font-family-sans-serif: 'Nunito Sans', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-bold: 800 !default;
$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;
$headings-margin-bottom: calc(1rem / 2) !default;
$headings-font-weight: 700 !default;
$headings-color: inherit !default;

// ======================================================
// Cards
// ======================================================
$card-border-width: 0px !default;
$card-border-color: transparent !default;
$card-border-radius: 0px !default;



// ======================================================
// Tables
// ======================================================
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;


// ======================================================
// Grid breakpoints
// ======================================================
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1600px) !default;

// ======================================================
// Navbar
// ======================================================
$navbar-dark-color: rgba($white, 0.8) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;