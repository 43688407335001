.page-wrapper {
  background: #ebeef7;
  position: relative;
  transition: 0.2s ease-in;
}

.card,
.card-body,
.card-body:hover {
  /*   border-radius: 5px; */
  width: 100%;
  height: auto;

  // margin-bottom: -5px;
}

/* Links should always stay the same color */
a {
  color: $body-color;

  &:hover {
    color: $body-color;
  }

  &:visited {
    color: $body-color;
  }
}

ul.list-style-none {
  li {
    a {
      color: $body-color;

      &:hover {
        color: $body-color;
      }
    }
  }
}

em {
  font-weight: bold;
  font-style: oblique;
  color: #f59301;
  /*   font-style: normal;
  background-color: yellow; */
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: 1s all;
  opacity: 0;
}

.loading.show {
  opacity: 1;
}

.loading .spin {
  border: 3px solid hsla(185, 100%, 62%, 0.2);
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.dropdown-menu {
  padding: 0 !important;
}

.dropdown-menu {
  top: 7px !important;
  border: 2px;

  :hover {
    background-color: #1c2834 !important;
    color: rgb(255, 255, 255) !important;
  }
}

.dropdown-divider {
  border-top: 1px solid #bfc4c9 !important;
  margin: 0.2rem 0 !important;
}

.height_group {
  height: 55px;
}

.margin-proj {
  margin-right: -6px;
}

.signUp {
  margin-left: 1em;
  padding: 0.5em;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #ff9800;
}

a {
  z-index: 1;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  // opacity: 0.7;
}

a:hover {
  opacity: 100;
}

.text-center {
  text-align: center !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

thead td {
  background-color: #f0d08a;
}

.card-body>button {
  margin-right: 3px;
}

/*******************
Padding margin property
*******************/

$vertical-property-map: (
  m-t-: margin-top,
  m-b-: margin-bottom,
  m-r-: margin-right,
  m-l-: margin-left,
  p-t-: padding-top,
  p-b-: padding-bottom,
  p-r-: padding-right,
  p-l-: padding-left,
  p-: padding,
  m-: margin
);
$sizes-list: 5 10 15 20 25 30 40;

@each $size in $sizes-list {
  $val: $size +0px;

  @each $keyword,
  $property in $vertical-property-map {
    html body .#{$keyword}#{$size} {
      #{$property}: $val;
    }
  }
}

/** * Zero */

$zero-property-map: (
  m-0: margin,
  m-t: margin-top,
  m-r: margin-right,
  m-b: margin-bottom,
  m-l: margin-left,
  p-0: padding,
  p-t: padding-top,
  p-r: padding-right,
  p-b: padding-bottom,
  p-l: padding-left
);

@each $keyword,
$property in $zero-property-map {
  html body .#{$keyword}-0 {
    #{$property}: 0;
  }
}