// Sidebar
$sidebar-link-padding: 14px 15px;
$sidebar-icon-size: 23px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 15px;
$sidebar-first-level-icon-size: 14px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  // width: 100%;
  height: 100%;
  top: 0px;
  z-index: 0;
  padding-top: 64px;
  background: $sidebar;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  position: relative;
}


/*******************
User Profile
******************/
.user-profile {
  padding: 15px;

  .user-content,
  .user-name,
  .user-email {
    color: $theme-light;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a:after {
    display: none;
  }
}